<template>
  <v-autocomplete
    v-model="source"
    :items="sources"
    :label="$tc('source', 1) | capitalize"
    xoutlined
    dense
    multiple
    small-chips
    clearable
    menu-props="offsetY"
    @change="$emit('update:value', source)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        fal fa-vial
      </v-icon>
    </template>
    <template #selection="{ item }">
      <source-icon
        :key="item.value"
        :source="item.value"
        :name="item.text"
      />
    </template>
    <template #item="{ item }">
      <source-chip
        :key="item.text"
        :source="item.value"
        :name="item.text"
        no-tooltip
      />
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    components: {
      SourceIcon: () => import('@/components/source/SourceIcon.vue'),
      SourceChip: () => import('@/components/source/SourceChip.vue'),
    },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        loading: false,
        source: this.value,
      }
    },
    computed: {
      sources () {
        return [...this.$sources].map(source => {
          source.text = this.$t(`sources.${source.value}`)
          source.text = source.text.charAt(0).toUpperCase() + source.text.slice(1)
          return source
        }).sort((a, b) => a.text.localeCompare(b.text))
      },
    },
    watch: {
      value () {
        this.source = this.value
      },
    },
  }
</script>
